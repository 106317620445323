<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex justify-space-between align-center w-100">
                <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                        <span class="font-weight-bold secondary--text">Organization tokens</span>
                        <v-btn :loading="fetchingOrgTokens" @click="$store.dispatch('orgStore/fetchOrgTokens', $route.params.oid)" icon>
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                    <span v-if="fetchingOrgTokens" class="caption text-uppercase">fetching tokens...</span>
                    <span v-else class="ma-0 caption">{{ orgTokens.length }} tokens found</span>
                </div>
                <div>
                    <CreateAndEditTokenDialog :disabled="fetchingOrgTokens" v-if="isOrgManager"></CreateAndEditTokenDialog>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader v-if="fetchingOrgTokens" class="mx-auto" type="table"></v-skeleton-loader>
            <template v-else>
                <div v-if="orgTokenData.length">
                    <v-text-field label="Search for a token..." autofocus v-model="search" outlined dense></v-text-field>
                    <v-data-table
                        :headers="headers"
                        :items="orgTokenData"
                        :items-per-page="100"
                        :footer-props="{ itemsPerPageOptions }"
                        item-key="otknid"
                        :search="search">
                        <template v-slot:[`item.type`]="{ item }">
                            <div class="d-flex align-center">
                                <v-icon class="mr-1" small>mdi-key</v-icon>
                                {{ getTokenType(item.token_type) }}
                            </div>
                        </template>
                        <template v-slot:[`item.scope`]="{ item }">{{ getTokenScope(item.scope) }}</template>
                        <template v-slot:[`item.token`]="{ item }">
                            <template v-if="item.visible">
                                <div v-if="isJson(item.token)">
                                    <ul class="py-3 px-0" style="list-style-type: none">
                                        <li v-for="(key, index) in Object.keys(JSON.parse(item.token))" :key="index">
                                            <div v-if="item.visible" class="d-flex align-center">
                                                <span class="caption font-weight-bold text-uppercase secondary--text mr-2">{{ key }}:</span>
                                                <span class="subtitle-2 secondary--text">{{ JSON.parse(item.token)[key] }}</span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <copy-to-clipboard
                                                                :textToCopy="JSON.parse(item.token)[key]"
                                                                buttonClass="caption font-weight-bold"
                                                                buttonColor="secondary"
                                                                buttonName="copy to clipboard"
                                                                :iconButton="true"
                                                                :isSmall="true"></copy-to-clipboard>
                                                        </div>
                                                    </template>
                                                    <span>Copy to clipboard</span>
                                                </v-tooltip>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <span v-else class="subtitle-2 secondary--text">{{ item.token }}</span>
                            </template>
                            <span v-else>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="d-flex align-center">
                                <v-btn v-if="!item.visible" @click="item.visible = true" icon><v-icon>visibility_off</v-icon></v-btn>
                                <v-btn v-if="item.visible" @click="item.visible = false" icon><v-icon>visibility</v-icon></v-btn>
                                <CreateAndEditTokenDialog
                                    v-if="isOrgManager"
                                    dialogAction="editToken"
                                    :isIconDialog="true"
                                    :tokenData="item"
                                    activatorIcon="edit"></CreateAndEditTokenDialog>
                                <SSHDeleteDialog
                                    v-if="isOrgManager"
                                    :token="item.token"
                                    dialogTitle="Token Delete"
                                    :apiURL="`/org_tokens/${item.otknid}`"
                                    :fetchTokensDispatch="
                                        () => {
                                            $store.dispatch('orgStore/fetchOrgTokens', $route.params.oid)
                                        }
                                    "></SSHDeleteDialog>
                            </div>
                        </template>
                    </v-data-table>
                </div>
                <v-banner v-else two-line>
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">info</v-icon>
                    </v-avatar>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold secondary--text mb-1">No tokens found</span>
                        <span>
                            Currently you don't have any token in this organization.
                            <span v-if="isOrgManager">
                                You can add a new token via the
                                <v-icon small>add</v-icon>
                                <span class="font-weight-bold text-uppercase primart--text">CREATE NEW TOKEN</span>
                                button above.
                            </span>
                        </span>
                    </div>
                </v-banner>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isJson, getObjectValueByKey } from '@/utils'
import { enumsData } from '@/mixins/enums'
const SSHDeleteDialog = () => import('@/components/DeleteTokenDialog.vue')
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const CreateAndEditTokenDialog = () => import('../components/TheOrgCreateAndEditTokenDialog')
export default {
    mixins: [enumsData],
    data() {
        return {
            expand: false,
            headers: [
                { text: 'Type', align: 'left', value: 'type', width: '10%' },
                { text: 'Scope', align: 'left', value: 'scope', width: '10%' },
                { text: 'Token', align: 'start', value: 'token', width: '70%' },
                { text: 'Actions', align: 'center', value: 'actions', width: '10%' }
            ],
            itemsPerPageOptions: [-1, 100],
            search: '',
            tokenVisibilityModels: null,
            orgTokenData: []
        }
    },
    components: { SSHDeleteDialog, CopyToClipboard, CreateAndEditTokenDialog },
    methods: {
        isJson: function (str) {
            return isJson(str)
        },
        getTokenScope: function (tokenScopeCode) {
            return getObjectValueByKey(this.orgTokenScopes, tokenScopeCode)
        },
        getTokenType: function (tokenTypeCode) {
            return getObjectValueByKey(this.orgTokenTypes, tokenTypeCode)
        },
        setOrgTokens: function () {
            if (this.orgTokens && this.orgTokens.length) {
                this.orgTokenData = this.orgTokens.map(function (token) {
                    const tokenData = Object.assign({}, token)
                    tokenData.visible = false
                    return tokenData
                })
            }
        }
    },
    computed: {
        ...mapState('orgStore', ['orgTokens', 'fetchingOrgTokens']),
        ...mapGetters('orgStore', ['isOrgManager'])
    },
    mounted() {
        this.setOrgTokens()
    },
    watch: {
        orgTokens: function (nextVal) {
            if (nextVal) {
                this.setOrgTokens()
            }
        }
    }
}
</script>
